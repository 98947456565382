import './bootstrap';
import './general/functions';
import './general/admin-lte';
import './general/datePicker';
import './general/select2';
import './general/inputmask';
import './general/dataTables';
// import './general/fullCalendar';

//import './includes/events';
import './includes/user';
import './includes/culture';
import './includes/sidebar';
import './includes/report';
import './includes/field';
import './includes/pesticide';
import './includes/manure';
import './includes/livestock';
import './includes/slaughtery';
import './includes/veterinary';
import './includes/livestockUserStock';
import './includes/product';
import './includes/productReport';
// Create MVC

$(function() {
	// We can attach the `fileselect` event to all file inputs on the page
	$(document).on('change', ':file', function() {
		var input = $(this),
			numFiles = input.get(0).files ? input.get(0).files.length : 1,
			label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
		input.trigger('fileselect', [numFiles, label]);
	});
});

jQuery(function ($) {
    $('#alert').delay(3000).hide(300);
    $('.treeview-menu li').each(function(){
        if ($(this).hasClass('active'))
            $(this).parent().parent().addClass('active');
    });
    $('.browse-button').on('click', function() {
        $('.browse-file').click();
    });
    $('.browse-input').on('click', function() {
        $('.browse-file').click();
    });
    $('.browse-file').on('fileselect', function(event, numFiles, label) {
        $('.browse-input').val(label);
    })
});
