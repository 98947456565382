$('.product-delete').on('click', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#product-question').data('row', row);
    $('#product-question').data('id', id);
});
$('.product-delete-modal #modalSubmit').on('click', function(){
    var id = $('#product-question').data('id');
    var row = $('#product-question').data('row');
    axios.delete(route('product.delete', {product_id:id}))
        .then(function (response) {
            $('body .wrapper').append('<div id="alert" class="alert alert-success">'+response.data.status+'</div>');
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
            $('#alert').delay(3000).hide(300);
        })
        .catch(function (error) {
            console.log(error);
        });
});