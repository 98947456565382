$('.livestock-user-stock-delete').on('click', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#livestock-user-stock-question').data('row', row);
    $('#livestock-user-stock-question').data('id', id);
});
$('.livestock-user-stock-delete-modal #modalSubmit').on('click', function(){
    var id = $('#livestock-user-stock-question').data('id');
    var row = $('#livestock-user-stock-question').data('row');
    axios.delete(route('livestock_user_stock.delete', {livestock_user_stock_id:id}))
        .then(function (response) {
            $('body .wrapper').append('<div id="alert" class="alert alert-success">'+response.data.status+'</div>');
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
            $('#alert').delay(3000).hide(300);
        })
        .catch(function (error) {
            console.log(error);
        });
});

$('.livestock-individual-delete').on('click', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#livestock-individual-question').data('row', row);
    $('#livestock-individual-question').data('id', id);
});
$('.livestock-individual-delete-modal #modalSubmit').on('click', function(){
    var id = $('#livestock-individual-question').data('id');
    var row = $('#livestock-individual-question').data('row');
    axios.delete(route('livestock_report.delete', {livestock_report_id:id}))
        .then(function (response) {
            $('body .wrapper').append('<div id="alert" class="alert alert-success">'+response.data.status+'</div>');
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
            $('#alert').delay(3000).hide(300);
        })
        .catch(function (error) {
            console.log(error);
        });
});